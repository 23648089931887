<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Profesional</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-2">
          <v-row dense>
            <v-col cols="9" sm="6" md="7">
              <v-text-field
                v-model="profesional.matricula"
                prepend-icon="mdi-account"
                label="Matrícula"
                dense
                required
                @keypress="onlyNumber"
                @keypress.enter="buscarProfesionalMatricula"
                @blur="buscarProfesionalMatricula"
                :rules="requiredRules"
                maxlength="9"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="profesional.apellidos"
                prepend-icon="mdi-account"
                label="Apellidos"
                maxlength="50"
                :rules="requiredRules"
                @blur="upper"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="profesional.nombres"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="50"
                :rules="requiredRules"
                @blur="upperNombres"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="profesional.fechaNac"
                :value="profesional.fechaNac"
                label="Fecha de Nacimiento"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="sexo"
                v-model="profesional.sexo"
                item-text="profesional.sexo"
                item-value="profesional.sexo"
                label="Sexo"
                dense
                prepend-icon="mdi-account"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="profesional.direccion"
                prepend-icon="mdi-map-marker"
                label="Direccion"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="profesional.localidad"
                prepend-icon="mdi-map-marker"
                label="Localidad"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="profesional.celular"
                prepend-icon="mdi-cellphone"
                label="Celular"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="profesional.telefono"
                prepend-icon="mdi-phone"
                label="Telefono"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="profesional.mail"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="profesional.matEsp"
                prepend-icon="mdi-account"
                label="Mat. Especialista"
                dense
                required
                @keypress="onlyNumber"
                maxlength="9"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="profesional.cantMaxEt"
                prepend-icon="mdi-account"
                label="Cant. Máx. ET"
                dense
                required
                hint="Define la cantidad máxima de entreturnos por día para el profesional. Si deja el campo vacío aceptará entreturnos ilimitados."
                @keypress="onlyNumber"
                maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                :items="servicios"
                item-text="nombre"
                item-value="idServicio"
                menu-props="auto"
                label="Especialidad"
                hide-details
                prepend-icon="mdi-account"
                v-model="profesional.servicio"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="profesional.observaciones"
                prepend-icon="mdi-account"
                label="Observaciones del Profesional"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                :items="consultorios"
                item-text="nombre"
                item-value="idConsultorio"
                menu-props="auto"
                label="Consultorio"
                hide-details
                prepend-icon="mdi-account"
                v-model="idConsultorio"
                dense
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              v-if="$store.state.usuario.institucion.esEmpresa"
            >
              <v-select
                :items="sedes"
                item-text="nombre"
                item-value="idObraSocial"
                menu-props="auto"
                prepend-icon="mdi-account"
                hide-details
                v-model="sede"
                label="Sede"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="3">
              <v-file-input
                :rules="rules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                v-model="profesional.foto"
                show-size
                chips
                class="mb-0 pb-0"
                label="Foto"
                hint="La imágen debe ser .jpg o .png y pesar 5MB o menos"
                persistent-hint
                dense
                @change="displayPhoto"
                @click:clear="displayPhoto"
              ></v-file-input>
            </v-col>

            <v-col class="pr-0 mr-0 pt-0 mt-0 ml-0" cols="12" sm="4" md="2">
              <v-badge
                color="error"
                overlap
                offset-y="40"
                offset-x="40"
                v-if="src != '' && src != null"
              >
                <v-icon slot="badge" dark x-small @click="borrarFoto"
                  >mdi-close</v-icon
                >
                <v-avatar size="160" tile>
                  <v-img
                    class="pr-0 mr-0 ml-0"
                    max-height="100px"
                    max-width="100px"
                    :aspect-ratio="1"
                    :src="src"
                    position="top"
                  >
                  </v-img>
                </v-avatar>
              </v-badge>
            </v-col>
            <v-col cols="12" md="4" sm="4" class="mt-1">
              <v-text-field
                v-model="profesional.accessToken"
                prepend-icon="mdi-account"
                label="Token Mercado Pago"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="mt-1">
              <v-text-field
                v-model="profesional.valorConsulta"
                prepend-icon="mdi-currency-usd"
                label="Valor Consulta Particular"
                @blur="toFixed"
                @keypress="onlyNumberWithDot"
                dense
                maxlength="10"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="loading === true" class="loader mr-2"></div>
          <h4 v-if="loading === true">Modificando</h4>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="modificarProfesional"
            >Guardar</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";
import { getData } from "../../api/apiService";

export default {
  data: (vm) => ({
    horaTurno: null,
    servicios: [],
    profesional: {
      matricula: null,
      apellidos: null,
      nombres: null,
      fechaNac: null,
      sexo: null,
      direccion: null,
      localidad: null,
      mail: null,
      telefono: null,
      celular: null,
      observaciones: null,
      servicio: null,
      cantMaxEt: null,
      matEsp: null,
      foto: null,
      tmpFoto: null,
      accessToken: null,
      valorConsulta: null,
    },
    sede: null,
    sedes: [],
    tmpFoto: null,
    src: "",
    sexo: ["FEMENINO", "MASCULINO", "OTRO"],
    valid: false,
    lazy: false,
    consultorios: [],
    idConsultorio: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    rules: [
      (value) =>
        !value || value.size < 5242880 || "La foto debe pesar 5MB o menos",
    ],
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
    ],
    loading: false,
  }),

  props: ["visible", "idProfesional", "matriculaActual"],

  computed: {
    show: {
      get() {
        if (this.visible) {
          this.cargarServicios();
          this.cargarConsultorios();
          if (this.$store.state.usuario.institucion.esEmpresa) {
            this.cargarSedes();
          }
          this.buscarDatosProfesional();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          this.profesional.dni = "";
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    onlyNumberWithDot($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    toFixed() {
      if (isNaN(parseFloat(this.profesional.valorConsulta)))
        this.profesional.valorConsulta = parseFloat(0.0).toFixed(2);
      else
        this.profesional.valorConsulta = parseFloat(
          this.profesional.valorConsulta
        ).toFixed(2);
    },
    async cargarSedes() {
      try {
        const params = {
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        const obrasSocialesData = await getData(
          "/ObraSocial/GetObraSocialActivasDeInst",
          params
        );
        this.sedes = obrasSocialesData;
        this.sede = this.sedes[0].idObraSocial;
      } catch (error) {
        console.log(error.message);
      }
    },
    borrarFoto() {
      this.src = "";
      this.profesional.foto = null;
    },
    buscarDatosProfesional() {
      const idprofesional = this.idProfesional;
      let self = this;

      axios
        .get("/Profesional/GetProfesionalPorId?", {
          params: {
            idprofesional: idprofesional,
          },
        })
        .then((response) => {
          self.profesional.matricula = response.data.matricula;
          self.profesional.apellidos = response.data.apellidos;
          self.profesional.nombres = response.data.nombreProfesional;
          if (response.data.fechanacimiento == "1900-01-01T00:00:00")
            self.profesional.fechaNac = null;
          else
            self.profesional.fechaNac = response.data.fechanacimiento.substring(
              0,
              10
            );
          self.profesional.sexo = response.data.sexo;
          self.profesional.localidad = response.data.localidad;
          self.profesional.direccion = response.data.calleynumero;
          self.profesional.celular = response.data.celular;
          self.profesional.mail = response.data.email;
          self.profesional.telefono = response.data.telefono;
          self.profesional.observaciones = response.data.observaciones;
          self.profesional.matEsp = response.data.matEsp;
          if (response.data.cantMaxEt == 999) self.profesional.cantMaxEt = null;
          else self.profesional.cantMaxEt = response.data.cantMaxEt;
          self.profesional.servicio = response.data.servicio;
          self.idConsultorio = response.data.idConsultorio;
          self.tmpFoto = response.data.foto;
          self.src = response.data.foto;
          self.profesional.accessToken = response.data.accessToken;
          self.sede = response.data.idSede;
          self.profesional.valorConsulta = response.data.valorConsulta
            ? parseFloat(response.data.valorConsulta).toFixed(2)
            : "";
          //this.profesional.antecedentes = response.data.antecedentes;
        })
        .catch((error) => {});
    },
    displayPhoto() {
      this.src = "";
      if (this.profesional.foto != "" && this.profesional.foto != null) {
        const data = URL.createObjectURL(this.profesional.foto);
        this.src = data;
      }
    },
    cargarServicios() {
      let self = this;
      axios
        .get("/Profesional/GetServicios")
        .then((response) => {
          self.servicios = response.data;
        })
        .catch((error) => {});
    },
    cargarConsultorios() {
      const filtrosData = {
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Profesional/GetConsultoriosDeInst", {
          params: {
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.consultorios = response.data;
          else self.consultorios = [];
        })
        .catch((error) => {});
    },
    modificarProfesional() {
      this.$swal({
        title: "Modificar Profesional",
        text: "¿Desea modificar los datos del profesional?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')
          var datosProfesional = new FormData();
          datosProfesional.append(
            "idInstitucion",
            this.$store.state.usuario.institucion.idInstitucion
          );
          datosProfesional.append("matricula", this.profesional.matricula);
          datosProfesional.append("apellidos", this.profesional.apellidos);
          datosProfesional.append("nombres", this.profesional.nombres);
          if (this.profesional.fechaNac == null)
            datosProfesional.append("fechanacimiento", "01/01/1900");
          else
            datosProfesional.append(
              "fechanacimiento",
              this.profesional.fechaNac
            );
          datosProfesional.append("sexo", this.profesional.sexo);
          datosProfesional.append("calleynumero", this.profesional.direccion);
          datosProfesional.append("localidad", this.profesional.localidad);
          datosProfesional.append("celular", this.profesional.celular);
          datosProfesional.append("telefono", this.profesional.telefono);
          datosProfesional.append("email", this.profesional.mail);
          datosProfesional.append(
            "observaciones",
            this.profesional.observaciones
          );
          datosProfesional.append("nrodocumento", "");
          datosProfesional.append("cuitCuil", "");
          if (this.profesional.servicio == null)
            datosProfesional.append("servicio", 0);
          else datosProfesional.append("servicio", this.profesional.servicio);
          if (this.profesional.cantMaxEt == null)
            datosProfesional.append("cantMaxEt", 999);
          else datosProfesional.append("cantMaxEt", this.profesional.cantMaxEt);

          datosProfesional.append("matEsp", this.profesional.matEsp);
          datosProfesional.append("estado", "Activo");
          datosProfesional.append("foto", this.profesional.foto);
          datosProfesional.append("idProfesional", this.idProfesional);
          datosProfesional.append("idConsultorio", this.idConsultorio);
          if (
            (this.tmpFoto == null || this.tmpFoto == "") &&
            (this.src == null || this.src == "")
          ) {
            datosProfesional.append("modiFoto", 0);
            datosProfesional.append("ruta", this.tmpFoto);
          }
          if (
            (this.tmpFoto != null || this.tmpFoto != "") &&
            (this.src != null || this.src != "") &&
            this.tmpFoto == this.src
          ) {
            datosProfesional.append("modiFoto", 0);
            datosProfesional.append("ruta", this.tmpFoto);
          }
          if (
            (this.tmpFoto == null || this.tmpFoto == "") &&
            (this.src != null || this.src != "")
          ) {
            datosProfesional.append("modiFoto", 1);
          }
          if (
            (this.tmpFoto != null || this.tmpFoto != "") &&
            (this.src == null || this.src == "")
          ) {
            datosProfesional.append("modiFoto", 1);
            datosProfesional.append("ruta", this.tmpFoto);
          }
          if (
            (this.tmpFoto != null || this.tmpFoto != "") &&
            (this.src != null || this.src != "") &&
            this.tmpFoto != this.src
          ) {
            datosProfesional.append("modiFoto", 1);
          }
          datosProfesional.append("accessToken", this.profesional.accessToken);
          datosProfesional.append("idSede", this.sede);
          datosProfesional.append(
            "valorConsultaString",
            this.profesional.valorConsulta.toString().replace(".", ",")
          );

          let self = this;
          axios({
            method: "post",
            url: "/Profesional/ModificarProfesional",
            data: datosProfesional,
            header: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              self.profesional.matricula = "";
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Profesional modificado exitosamente",
                vm: self,
              });
              self.$emit("close");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La foto debe estar en formato .jpg o .png",
                vm: this,
              });
            })
            .finally(() => {
              self.loading = false;
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    upper() {
      if (this.profesional.apellidos != null)
        this.profesional.apellidos = this.profesional.apellidos
          .toString()
          .toUpperCase();
    },
    upperNombres() {
      if (this.profesional.nombres != null)
        this.profesional.nombres = this.profesional.nombres
          .toString()
          .toUpperCase();
    },

    vaciarModels() {
      this.profesional.apellidos = "";
      this.profesional.nombres = "";
      this.profesional.direccion = "";
      this.profesional.localidad = "";
      this.profesional.celular = "";
      this.profesional.telefono = "";
      this.profesional.mail = "";
      this.profesional.observaciones = "";
      this.profesional.antecedentes = "";
      this.profesional.fechaNac = null;
      this.profesional.sexo = null;
      this.profesional.foto = null;
      this.profesional.accessToken = "";
      this.profesional.valorConsulta = "";

      this.src = "";
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    buscarProfesionalMatricula() {
      if (
        this.profesional.matricula === null ||
        this.profesional.matricula === "" ||
        this.profesional.matricula === undefined
      ) {
        return;
      }
      const profesionalesData = {
        mp: this.profesional.matricula,
        idInst: this.$store.state.usuario.institucion.idInstitucion,
        idProfesional: this.idProfesional,
      };
      let self = this;
      axios
        .get("/Profesional/GetProfesionalPorMpeInstModi?", {
          params: {
            mp: profesionalesData.mp,
            idInst: profesionalesData.idInst,
            idProfesional: profesionalesData.idProfesional,
          },
        })
        .then(function (response) {
          if (response.data != self.idProfesional) {
            self.showAlert({
              icon: "error",
              title:
                "Matrícula registrada para el profesional: " +
                response.data.apellidos +
                ", " +
                response.data.nombreProfesional,
              vm: self,
            });
            self.profesional.matricula = self.matriculaActual;
          }
        })
        .catch(function (error) {});
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  created() {
    this.vaciarModels();
  },
};
</script>
<style scoped>
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
